/*@import url("https://fonts.googleapis.com/css2?family=Blinker:wght@100;300;400;600&display=swap");*/

:root {
  --red: #ff005d;
  --blue: #4211e7;
  --purple: #8118f3;
  --orange: #ffa300;
  --orange-light: #FFD57CFF;
  --dark: #150047;
  --white: #ffffff;
  --dark-grey: #666;
  --grey: #c4c4c4;
  --light-grey: #F0F0F0;
  --light-grey-60: #F6F6F6;
  --light-grey-40: #F9F9F9;


  --primary: #ff005d;
  --secondary: #4211e7;
  --tertiary: #8118f3;
  --quaternary: #ffa300;


  --padding-inline: 0 2rem;
  --font: "Blinker", sans-serif;
}

@media (prefers-color-scheme: dark) {
  :root {

  }
}

html {
  scroll-padding-top: 6rem;
}


body {
  font-family: var(--font);
  color: var(--dark);
  max-width: 100vw;

  h2, h3, h4 {
    font-weight: 400;
  }

  .page {
    margin-top: 6rem;
    width: 100vw;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    p {
      color: var(--dark);
      font-size: 14pt;
      font-weight: normal;
      margin: 50px;
    }
  }
}

* {
  margin: 0;
  padding: 0;
  color: unset;
  box-sizing: border-box;
  text-decoration: none;
  outline: none;
  list-style: none;
  overflow-x: visible;
  scroll-behavior: smooth;

}

img {
  overflow-x: auto;
}

/* Buttons */

.button {
  font-size: 1rem;
  line-height: 1.5rem;
  padding: 0.5rem 1.5rem;
  border: solid 1px var(--dark);
  text-align: center;
  background-color: var(--dark);
  color: var(--white);
  cursor: pointer;
  font-family: var(--font);
  svg {
    vertical-align: middle;
    width: 1rem;
    height: 1rem;
    margin-right: 0.5rem;
    color: var(--white);
  }
}
.big-button {
  font-size: 2rem;
  font-weight: 300;
  line-height: 2rem;
  padding: 1rem 2rem;
}

.button:hover {
  background-color: var(--white);
  color: var(--dark);
  border-color: var(--white);

  svg {
    color: var(--dark);
  }
}

.button.wired {
  background-color: transparent;
  color: var(--dark);
}
.button.wired:hover {
  background-color: var(--dark);
  color: var(--white);
}

.button.red {
  background-color: var(--red);
  color: var(--white);
  border-color: var(--red);
}
.button.red:hover {
  background-color: var(--dark);
  color: var(--white);
  border-color: var(--white);
}
.button.wired.red {
  background-color: transparent;
  color: var(--red);
}
.button.wired.red:hover {
  background-color: var(--red);
  color: var(--white);
}


.button.white {
  font-size: 1rem;
  line-height: 1.5rem;
  padding: 0.5rem 1.5rem;
  border: solid 1px var(--white);
  background-color: var(--white);
  color: var(--dark);
  cursor: pointer;
  font-family: var(--font);
}
.button.white:hover {
  background-color: var(--dark);
  color: var(--white);
}

.button.wired.white {
  background-color: transparent;
  color: var(--white);
}
.button.wired.white:hover {
  background-color: var(--white);
  color: var(--dark);
}

.button.blue {
  background-color: var(--blue);
  color: var(--white);
  border-color: var(--blue);
}
.button.blue:hover {
  background-color: var(--dark);
  color: var(--white);
  border-color: var(--white);
}
.button.wired.blue {
  background-color: transparent;
  color: var(--blue);
}
.button.wired.blue:hover {
  background-color: var(--blue);
  color: var(--white);
}

.button.orange {
  background-color: var(--orange);
  color: var(--white);
  border-color: var(--orange);
}
.button.orange:hover {
  background-color: var(--dark);
  color: var(--white);
  border-color: var(--white);
}
.button.wired.orange {
  background-color: transparent;
  color: var(--orange);
}
.button.wired.orange:hover {
  background-color: var(--orange);
  color: var(--white);
}

.button.white {
  background-color: var(--white);
  color: var(--dark);
  border-color: var(--white);
}
.button.white:hover {
  background-color: var(--dark);
  color: var(--white);
  border-color: var(--white);
}
.button.wired.white {
  background-color: transparent;
  color: var(--white);
}
.button.wired.white:hover {
  background-color: var(--white);
  color: var(--dark);
}

/* zoom out / in image */

.zoom-in, .zoom-out {
  overflow: hidden;
}

.zoom-in,
.zoom-out {
  &::after {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: inherit;
    background-size: cover;
    transform-origin: center;
    transition: transform .4s ease-in-out;
  }
}

.zoom-in {
  &:focus,
  &:hover {
    &::after {
      transform: scale(1.05);
    }
  }
}

.zoom-out {
  &::after {
    transform: scale(1.05);
  }

  &:focus,
  &:hover {
    &::after {
      transform: scale(1);
    }
  }
}


/* Animations */



@keyframes rotate_loaders {
  0% {
    transform: rotate(calc(0deg));
    animation-timing-function: ease-in;
  }
  10% {
    transform: rotate(0deg);
  }
  25% {
    transform: rotate(calc(360deg * 1));
    animation-timing-function: ease-in;
  }
  35% {
    transform: rotate(calc(360deg * 1));
  }
  50% {
    transform: rotate(calc(360deg * 2 ));
    animation-timing-function: ease-in;
  }
  60% {
    transform: rotate(calc(360deg * 2));
  }
  75% {
    transform: rotate(calc(360deg * 3));
    animation-timing-function: ease-in;
  }
  85% {
    transform: rotate(calc(360deg * 3 ));
  }
  100% {
    transform: rotate(calc(360deg * 4));
    animation-timing-function: ease-in;
  }
}


@keyframes spin_words {
  10% {
    transform: translateY(-112%);
  }
  25% {
    transform: translateY(-100%);
  }
  35% {
    transform: translateY(-212%);
  }
  50% {
    transform: translateY(-200%);
  }
  60% {
    transform: translateY(-312%);
  }
  75% {
    transform: translateY(-300%);
  }
  85% {
    transform: translateY(-412%);
  }
  100% {
    transform: translateY(-400%);
  }
}
